import React from "react";
import { useParams } from "react-router-dom";
import { Structural } from "../../CoreServices";

export default function ServiceDetails() {
  const { itemName } = useParams();
  const currentService = Structural.find(
    (service) => service.CoreServicesName === decodeURIComponent(itemName)
  );

  if (!currentService) {
    return <h2>Service not found</h2>;
  }

  return (
    <div>
      <div className="row w-100 p-5">
        <div className="col-8">
          <h2 className="text-e2">{currentService.CoreServicesName}</h2>
          <p className="textp1">{currentService.CoreServicesDetails1}</p>
          <p className="textp1">{currentService.CoreServicesDetails2}</p>
          <p className="textp1">{currentService.CoreServicesDetails3}</p>
        </div>
        <div className="col-4 m-auto">
          <img src={currentService.Sample} style={{ width: "100%" }} />
        </div>
        <div>
          <div>
            <h2 className="text-e2 mt-4 mb-4">
              {currentService.DetailingServices}
            </h2>
          </div>
          <div>
            <div>
              <div className="row">
                {currentService.DetailingServicesName.match(
                  /<li>(.*?)<\/li>/g
                )?.map((item, itemIndex) => {
                  const itemName = item.replace(/<\/?li.*?>/g, "").trim();
                  return (
                    <div key={itemIndex} className="col-4 text-center">
                      <img
                        src={currentService.DetailingServicesSample}
                        className="rounded-circle w-100 mb-3"
                      />
                      <h4 className="textp1">{itemName}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
