import React from "react";
import { ExpertiseData } from '../data.js';
import "../css/Experties.css";
import "../css/home.css";
import { Link } from 'react-router-dom';
import { scrollToTop } from "../UtilityFunction/scrollToTop.js";

export default function Expertise() {

  return (
    <div style={{width:'100%',background:"#303031"}} className="p-5">
      <div className="row cardExperties justify-content-center">
        {ExpertiseData.map((expertise, index) => (
          <Link
            key={index}
            className="col-lg-5 col-md-5 col-sm-12 rounded rounded-5 m-3 p-0" to={`/WhatWeDo/${expertise.name}`} style={{textDecoration:"none"}}     onClick={() => {
              scrollToTop();
            
            }}>
        <div className="w-100" style={{height:'85%'}}>
              <img src={expertise.img} alt={expertise.name} className="img-e1 w-100 h-100"/>
              </div>
         <div style={{background:'#000000',height:"15%"}} >
          
            <h4 className="d-flex justify-content-center text-e214" style={{color:'#fff'}}>{expertise.name}</h4>
            </div>
          
           
          </Link>
        ))}
      </div>
    </div>
  );
}
