import React from 'react';
import AboutHeader from './About/AboutHeader';
import CoreSeriver from './About/coreSeriver'; 
import Service from './About/sevice'; 


export default function service() {
  return (
    <div>
      <AboutHeader />
      <Service />
      <CoreSeriver /> 

      
    </div>
  );
}
