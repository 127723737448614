import Arch from "./pic/simple/Architectural_page-0001 (1).jpg";
import Rebar from "./pic/simple/Rebar arrangement sample_page-0001.jpg";
import Shop from "./pic/simple/Shop Drawing.pdf"
import Structuralpdf from "./pic/simple/Rebar arrangement sample.pdf";
import Structuralpdfall from "./pic/simple/Steel Shed structure.pdf";
import Archsample from "./pic/simple/Sample/Architectural.pdf";
import MEP from "./pic/simple/Sample/MEP.pdf"
import MEPPic from "./pic/simple/Rebar arrangement sample_page-0001.jpg"
import picSample1 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0001.jpg'
import picSample2 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0002.jpg'
import picSample3 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0003.jpg'
import picSample4 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0004.jpg'
import picSample5 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0005.jpg'
import picSample6 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0006.jpg'
import picSample7 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0007.jpg'
import picSample8 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0008.jpg'
import picSample9 from './pic/simple/ilovepdf_pages-to-jpg/Steel Shed structure_page-0009.jpg'
import picSample10 from './pic/simple/Estimation sample/commercial-samples-summary.png'
import picSample11 from './pic/simple/Estimation sample/concrete-color-sample (1).png'
import picSample12 from './pic/simple/Estimation sample/drywall-color-sample.png'
import  picSample13 from './pic/simple/Estimation sample/electrical-color-plan-sample.png'
import picSample14 from './pic/simple/Estimation sample/exterior-finishes-color-sample.png'
import picSample15 from './pic/simple/Estimation sample/lumber-color-plan.png'
import picSample16 from './pic/simple/Estimation sample/marked-1.jpg'
import picSample17 from './pic/simple/Estimation sample/metals-color-plan-sample.png'
import picSample18 from './pic/simple/Estimation sample/mosanry-color-plan-sample.png'
import picSample19 from './pic/simple/Estimation sample/multifamily-appartments-color-plan.png'
import picSample20 from './pic/simple/Estimation sample/painting-color-sample.png'
import picSample21 from './pic/simple/Estimation sample/plumbing-color-plan-samples.png'
import picSample22 from './pic/simple/Estimation sample/residential-color-plan (1).png'
import picSample23 from './pic/simple/Estimation sample/sitework-color-sample-.png'

const Structural = [
  {
    categories: "Structural",
    CoreServicesName: "Steel Detailing Services",
    CoreServicesDetails1: "EDCAT offers standardized, detailed, and precise Steel Shop Drawings and Steel Detailing Services globally at competitive rates. We partner with fabricators and structural design companies worldwide to augment their capacity in creating shop-ready, high-quality, and detailed structural steel drawings for large-scale and complex projects.",
    CoreServicesDetails2: "With our years of experience in handling Structural Steel Detailing Services for international clients, our expertise in interpreting structural design drawings and understanding design codes is unparalleled. You can count on our skilled team of structural design engineers and steel detailers to execute complex Steel Detailing projects according to applicable steel detailing standards such as AISC, BISC, Australian, and Canadian codes.",
    CoreServicesDetails3: "EDCAT’s list of executed projects includes residential and commercial buildings, industrial structures, educational and high-rise buildings, museums, stadiums, airports, bridges, highways, and more. We focus on consistency, quality, and precision while delivering Structural Steel Detailing Services for stairs, roofs, canopies, handrails, guardrails, ramps, breezeways, sheds, conveyor belts, machine structures, pipes, racks, and other complex structures.",
    Sample: picSample1,
    DetailingServices: "Structural Steel Detailing Services",
    DetailingServicesName: "<ul><li>Steel Detailing Services</li><li>Structural Shop Drawing Services</li><li>Miscellaneous Steel Detailing Services</li></ul>",
    DetailingServicesSample: "https://www.teslaoutsourcingservices.com/images/c-images/steel-detailing.webp",
    pdfsample:Structuralpdfall,
  },
  {
    categories: "Structural",
    CoreServicesName: "Rebar Detailing Services",
    CoreServicesDetails1: "EDCAT is an industry-leading company that delivers Rebar Detailing Services, improving coordination, reducing errors, and driving construction quality. With over the years of experience, a skilled multidisciplinary team, and a client-centric approach, we empower our clients to gain a competitive edge. Our expertise has been utilized by steel detailers, concrete contractors, general contractors, designers, and more across the USA, Canada, UK, Europe, and Australia.",
  CoreServicesDetails2: "We excel in preparing Rebar Shop Drawings that provide detailed information regarding steel connections, erection sequences, welds, lap splices, and more. These drawings are crucial for ironworkers, offering clear guidance on the placement and installation of reinforced steel. We are well-versed in industry standards such as ASTM, CRSI, RSIO, ACI, AASHTO, BS 8666, and others, which apply across different countries. Additionally, we have rigorous quality checks in place to ensure all clashes are detected during the preconstruction stage, well before the first piece of rebar is bent or sheared.",
    CoreServicesDetails3: "In addition to rebar detailing, we provide quantity take-offs and cost estimation services to ensure the project avoids issues like lack of storage space, budget overruns, or time delays. We have successfully completed Rebar Detailing projects of varying sizes and complexities across residential, commercial, industrial, and infrastructure sectors.",
    Sample: picSample1,
    DetailingServices: "Our Rebar Detailing Services include",
    DetailingServicesName: "<ul><li>Foundation details</li><li>Rebar shop drawings</li><li>Rebar 3D modeling</li><li>Bar bending schedules</li><li>Concrete joint and slab details</li><li>Retaining walls detailing</li><li>As-built drawings</li><li>Concrete masonry detailing</li><li>Total rebar estimation</li><li>Bar listing in ASA format</li><li>Footing detailing</li><li>Grade beam detailing</li><li>Constructability Reviews</li><li>Roof truss and joint details</li><li>Quality take-offs</li></ul>",
    DetailingServicesSample:"",
    pdfsample:Structuralpdf,
  },
 
  {
    categories: "Structural",
    CoreServicesName: "Structural BIM Services",
    CoreServicesDetails1: "EDCAT is an excellent partner for any construction company seeking Structural BIM Services. Our clear objective is to provide clients with a competitive advantage by minimizing costs, enhancing customer service, and maximizing overall efficiency. We have an impeccable track record, successfully completing over 5000 projects for some of the leading construction companies in the US, Canada, Europe, and Australia.",
    CoreServicesDetails2: "We approach Structural BIM Modeling projects with consideration for existing structural designs, analysis, and calculations. Before the coordination process begins, we conduct design reviews and constructability reviews of the structural models to ensure accurate 3D models. Our team of Structural BIM Modelers, Steel Detailers, and Structural BIM Engineers is highly experienced in working on complex and detailed structural projects. They collaborate with our team of architects and MEP engineers to perform clash detection before on-site construction begins.",
    CoreServicesDetails3: "In addition to providing precise BIM Structural Services for complex designs and detailing, we seamlessly transform schematic designs or construction drawings into information-rich 3D BIM models. At EDCAT, we go the extra mile to ensure that high-quality Structural BIM Modeling Services are delivered within the stipulated timeframe and at competitive rates.",
    Sample: picSample2,
    DetailingServices: "Structural BIM Services",
    DetailingServicesName: "<ul><li>Structural BIM Modeling Services</li><li>Structural Quantity Takeoff Services</li><li>Structural Shop Drawing Services</li></ul>",
    DetailingServicesSample: "",
    pdfsample:Structuralpdfall,
      
  
   
  },
  
  {
    categories: "Structural",
    CoreServicesName: "Structural CAD Drafting",
    CoreServicesDetails1: "Are you looking for a company that can provide Structural Drafting Services with utmost precision? You’ve come to the right place! At EDCAT, we understand the intricacies of structural drawings and ensure that every micro detail is covered with complete accuracy. With our expertise in international codes, we adhere to those standards to deliver top-quality results ",
    CoreServicesDetails2: "EDCAT has demonstrated profound expertise in Structural Drafting Services for the past years, serving clients in countries such as the U.S.A., the U.K. Australia, Canada, and across Europe. We cater to client partners, including companies involved in steel fabrication, engineering construction, and more.",
    CoreServicesDetails3: "Our team of experts in Structural CAD Drafting Services consists of highly experienced structural engineers. They ensure that all CAD drafting projects meet the regulations, standards, and codes of each country. Additionally, they implement standardized methods for citing industry-standard line work, call-outs, notations, dimensions, symbols, as well as proper welding and finish annotations. You can rely on our Structural CAD Drafting Services for residential, commercial, and infrastructure projects of all sizes and complexities.",
    Sample: picSample4,
    DetailingServicesSample: "",
    DetailingServices:"",
    DetailingServicesName:"<ul><li>Structural BIM Modeling Services</li><li>Structural Quantity Takeoff Services</li><li>Structural Shop Drawing Services</li></ul>",
    pdfsample:Structuralpdfall,
  },{
    categories: "Structural",
    CoreServicesName: "As-Built Drawing Services",
    CoreServicesDetails1: "EDCAT provides qualitative and accurate As-Built Drawing Services. As an Architectural and Engineering Firm with over the years of experience, our in-house team of architects, engineers, MEP technicians, and BIM modelers work tirelessly to deliver time-tested and efficient solutions for complex projects.",
    CoreServicesDetails2: "As-Built Modeling Services are created upon project completion for documentation purposes. These 2D drawings or 3D models reflect any modifications or adjustments made on-site. Our As-Built drawings offer significant insight into the architectural, structural, and MEP components utilized in the building. We ensure that these drawings are plotted according to industry and building standards, including in-depth specifications like the location, name, function, shape, size, and other details of all building elements.",
    CoreServicesDetails3: "Our As-Built Drafting Services are utilized for surveys, maintenance, and reviews of existing buildings. They also facilitate the renovation and restoration process and can be applied to the construction and restoration of historical, residential, commercial, or industrial buildings.",
    Sample:picSample5,
    DetailingServicesSample: "",
    DetailingServices:"",
    DetailingServicesName:"",
    pdfsample:Structuralpdfall,
  }, {
    categories: "Structural",
    CoreServicesName: "Steel Shop Drawings Services",
    CoreServicesDetails1: "For over a decade, EDCAT has been delivering prompt Steel Shop Drawings Services to steel fabricators, design firms, contractors, and engineers at the most competitive rates. We work as an extended arm for our clients and constantly strive to deliver accurate Fabrication Drawings within the stipulated time frame. We are dependable and ensure the highest customer satisfaction for all our clients.",
    CoreServicesDetails2: "Structural shop drawings are diagrams, schedules, or other structural documents generated by steel detailers and structural engineers to aid the fabrication process. Our experienced team of structural steel detailing professionals is skilled in creating GA drawings (erection drawings, section drawings), assembly & detail drawings, single part/component drawings, structural 3D models, and advanced bills of materials (assembly list, part list, bolt list, etc.), as well as reports like NC files and KISS files.",
    CoreServicesDetails3: "EDCAT has profound experience working on commercial and industrial steel detailing projects. We can produce steel fabrication drawings for various steel elements in bridge projects, industrial projects, and commercial buildings, and provide detailing for elements like stairs and rails. Our steel detailers also have extensive experience delivering precise rebar detailing and fabrication drawings.",
    Sample: picSample6,
    DetailingServicesSample: "",
    DetailingServices:"",
    DetailingServicesName:"",
    pdfsample:Shop,
  },

  {
    categories: "Architectural",
    CoreServicesName: "Architectural BIM Services",
    CoreServicesDetails1:
      "EDCAT is a notable CAD and BIM service provider with a distinguished background in the Architectural and Engineering field. Over the years, we have achieved remarkable milestones in building a successful client base in the AEC industry. Our integrated service delivery approach makes it advantageous for architects, consultants, stakeholders, and contractors.",
    CoreServicesDetails2:
      "The core advantage of choosing us lies in our ability to offer a 360-degree service model in the architectural domain. We serve as a one-stop solution provider for architectural firms, construction companies, contractors, and builders by delivering comprehensive Architectural CAD and BIM Services, Construction Drawing sets, 3D Renderings, and 2D Drawings.",
    CoreServicesDetails3:
      "As expert Architectural BIM service providers, we have served over 100 clients globally in the field of Architectural BIM. Our adept team of BIM Architects and Engineers transforms CAD drawings, point clouds, sketches, PDFs, and more into detailed and analytical architectural BIM models. From basic concept design to Revit Construction Drawings and As-Built models, we have done it all. Our versatile portfolio includes Residential, Commercial, Mixed-Use, Heritage, and Industrial BIM models, and we can provide our clients with direct testimonials from trusted companies.",
    Sample: Arch,
    DetailingServices: "Our Architectural BIM Services Includes",
    DetailingServicesName:
      "<ul><li>Architectural Revit 3D Modeling</li><li>CAD Drafting to Architectural BIM</li><li>Site Modeling </li><li>Revit Family Creation</li><li>4D and 5D BIM</li><li>Revit Construction Drawings</li><li>Architectural Interior Modeling</li><li>Scan to BIM</li></ul>",
    DetailingServicesSample: "",
    pdfsample:Archsample,
  },
  {
    categories: "Architectural",
    CoreServicesName: "Architectural Drafting Services",
    CoreServicesDetails:
      "Our professionals at EDCAT, backed by years of global expertise, are here to provide you with the smoothest Architectural Drafting Services. If you have rough hand sketches or design drawings, we will transform them into detailed drawings ready for you within the stipulated time! From 2D drawings to Construction and Planning Drawings, we handle it all.",
    CoreServicesDetails0:
      "<p><b>Professional CAD Drafting Company Providing Architectural Drawing Services</b></p>",
    CoreServicesDetails1:
      "EDCAT has experts who are profoundly invested in creativity and technology. Equipped with the skills to complete any project within the stipulated period and the experience to deliver the most satisfying results, our professionals ensure the timely and cost-effective delivery of Architectural Drawing Services.",
    CoreServicesDetails2:
      "Our team of architects and engineers can accurately convert any hand sketches, PDFs, blueprints, laser scans, and design drawings into detailed architectural drawings. Our expertise also includes developing architectural floor plans, sections, scans to CAD conversions, paper to CAD conversions, as-built drawings, and more. We cover all kinds of buildings, from residential and commercial to historical monuments and educational institutions.",
    CoreServicesDetails3:
      "You will find all the building types we cover below. We also ensure continuous communication and support throughout the project, providing you with the best professional Architectural CAD Drafting Services with utmost transparency and effective communication.",
    Sample: Arch,
    DetailingServices: "Our expertise includes",
    DetailingServicesName:
      "<ul><li>Our expertise includes</li><li>Paper to CAD Conversion</li><li>Building Control Drawings</li><li>Wall Sections, Roof Section, etc.</li><li>Construction Drawing Sets</li><li>Architectural Permit Drawings</li><li>Detailing of Doors and Windows</li><li>Working Drawings</li><li>Presentation Drawings</li><li>Interior Drawings and Furniture</li><li>Layouts</li><li>As-Built Drawings</li><li>Sectional Elevation</li><li>Façade Drawings</li></ul>",
    DetailingServicesSample: "",
    pdfsample:Archsample,
  },
  {
    categories: "Architectural",
    CoreServicesName: "CAD Services",
    CoreServicesDetails1:
      "CAD Services for the USA</b><br/>EDCAT Outsourcing Services is a leading CAD company providing comprehensive end-to-end CAD solutions tailored for the USA construction industry. With years of experience serving some of the world’s most renowned construction firms, we are committed to understanding our clients' unique project requirements. By leveraging our expertise, we deliver quality outcomes with quick turnaround times.</p>",
    Sample: Arch,
    DetailingServices: "Our CAD Drafting Services includes",
    DetailingServicesName:
      "<ul><li>Architectural CAD Drafting</li><li>Structural CAD Drafting</li><li>MEP CAD Drafting</li><li>Civil CAD Drafting</li></ul>",
    DetailingServicesSample: "",
    BIMConversionServicesDetails1:
      "<p><b>Why Choose Us?<b><br/>Our superior and timely CAD drawing services empower construction companies to streamline their processes and gain a competitive edge. By outsourcing your CAD needs to EDCAT Outsourcing Services, you benefit from:</p>",
    BIMConversionServicesDetails2:
      "<ul><li>Expertise: A team of skilled professionals dedicated to delivering high-quality outcomes.</li><li>Efficiency: Faster turnaround times that keep your projects on schedule.</li><li>Cost-Effectiveness: Competitive pricing without compromising quality.</li></ul>",
    contact: "CONTACT US NOW",
    pdfsample:Archsample,
  },
  {
    categories: "Architectural",
    CoreServicesName: "Scan To BIM Services",
    CoreServicesDetails:
      "<p><b>Advanced SCAN TO BIM SERVICES for Surveyors and Architects</b></p>",
    CoreServicesDetails0:
      "EDCAT is a leading Scan to BIM company offering accurate Scan to BIM Services to the construction industry in the USA, UK, Australia, Canada, Ireland, Spain, France, Austria, Netherlands, Belgium, Switzerland, and other countries worldwide.",
    CoreServicesDetails1:
      "With an accuracy of 0-5 mm, our detail-rich Scan to BIM models and editable CAD files empower you to transform as-built environments into digital assets. All we require is laser survey point cloud data and your company standards—such as templates and drafting guidelines—to deliver the BIM model with the required Revit version, along with 2D as-built drawings that include dimensions, annotations, and layering. We work with all scan formats, including .rcp, .rcs, .e57, .pts, and many more.",
    CoreServicesDetails2:
      "Our team of architects, engineers, and modeling specialists accurately model the as-built environment using Autodesk ReCap and Revit. These 3D BIM models encompass all the Architectural, Structural, and MEP elements developed at the required level of detail (LOD 100 - LOD 500), adhering to international building codes and standards.",
    CoreServicesDetails3:
      "With our Scan to BIM Modeling Services, AEC professionals and stakeholders can analyze the as-built condition and make crucial decisions for renovation, refurbishment, and demolition projects. We convert point cloud data into information-rich 3D BIM models and 2D CAD Drawings for residential, infrastructural, and commercial projects.",
    Sample: Arch,
    BIMConversionServicesDetails1:
      "<p><b>Scan to BIM Modeling<b><br/>We are an expert provider of Scan to BIM Services, delivering high-quality Revit models from point cloud data. Our services can attach asset management data to the BIM models and deliver COBie data along with LOD 500 BIM models, which are essential for facility management.</p>",
    BIMConversionServicesDetails2:
      "<p><b>Scan to CAD<b><br/>We convert point cloud data, legacy drawings, site plans, non-CAD raster, or vector files into accurate and editable CAD files. Additionally, we create as-built drawings that accurately represent the existing conditions of the building with proper annotations and tagging.</p>",
    BIMConversionServicesDetails3:
      "<p><b>Scan to Mesh<b><br/>Our Scan to Mesh Services convert raw point cloud scan data into textured 3D meshes, including polygon, triangle, or surface models. Our team is skilled in creating precise meshes that can serve as standalone 3D objects or mesh models, where 2D sequences can be mapped out using 3D nodes.</p>",
    BIMConversionServicesDetails4:
      "<p><b>Scan to Revit Families<b><br/>We have extensive experience in creating Revit families from point cloud data. Our Scan to Revit Family Creation Services ensure that the BIM objects we create are of high quality and parametric in nature. We can produce all kinds of Revit families, including System, Loadable, and In-Place families, from laser scan data.</p>",
    DetailingServices: "",
    DetailingServicesName:
      "<ul><li>Architectural CAD Drafting</li><li>Structural CAD Drafting</li><li>MEP CAD Drafting</li><li>Civil CAD Drafting</li></ul>",
    DetailingServicesSample: "",
    pdfsample:Archsample,
  },
  {
    categories: "Architectural",
    CoreServicesName: "Construction Documentation",
    CoreServicesDetails:
      "<p><b>Code Compliant and Detailed Construction Documentation Services in the USA</b></p>",
    CoreServicesDetails0:
      "Are you looking for Construction Documentation Services in the USA? At EDCAT, we create standardized, detailed, and code-compliant AutoCAD and Revit CD sets that adhere to local building standards and codes. By outsourcing your construction drawing requirements to us, you gain access to the finest architects for your projects. Each year, we screen over 20,000 architects and handpick the best to ensure top-quality service.",
    CoreServicesDetails1:
      "With multiple engagement models available, you can choose the one that best fits your needs and benefit from our expertise at a fraction of the cost in the USA.",
    contact: "CONTACT US NOW",
    Sample: Arch,
    BIMConversionServicesDetails1:
      "<p><b>Delivering Code Compliant and Highly Detailed Construction Drawings to Architects and Builders in the USA<b></p>",
    BIMConversionServicesDetails2:
      "<p>For nearly two decades, EDCAT has been a leader in providing standardized, code-compliant, and highly detailed Construction Documentation Services to architectural firms and builders in the USA and globally. With a strong team of carefully selected architects and a proven track record of over 200 construction drawing projects in Revit and AutoCAD, we are well-equipped to handle complex construction documentation tasks.</p>",
    BIMConversionServicesDetails3:
      "<p>We understand that code compliance is a critical aspect of the construction drawing process. With us, you will receive standardized drawings created in accordance with IBC, IRC, IZC, and other applicable building standards and codes. Our expertise encompasses all types of residential, commercial, educational, and institutional buildings, and we ensure that your construction drawings meet the stringent requirements of local authorities.</p>",
    BIMConversionServicesDetails4:
      "<p>In addition to creating architectural plans, elevations, sections, and details, our Construction Documentation Services include creating specifications, schedules, and BOQs that are essential for bidding and procurement processes. By partnering with us, you can streamline your construction documentation workflow and ensure the timely completion of your projects.</p>",
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:Archsample,
  },
  {
    categories: "Architectural",
    CoreServicesName: "3D Rendering Services",
    CoreServicesDetails: "Premium 3D Rendering Services</b></p>",
    CoreServicesDetails0:
      "Transform your vision into reality with our photorealistic 3D Rendering Services. Specializing in residential, commercial, and institutional structures, EDCAT provides lifelike architectural renderings that bring your projects to life even before construction begins.",
    CoreServicesDetails1:
      "CONTACT OUR CGI EXPERTS<br/>Unmatched Quality in 3D Architectural Visualization</b></p>",
    Sample: Arch,
    BIMConversionServicesDetails1:
      "At EDCAT, we blend artistic vision with technical expertise to create stunning 3D visualizations. Our team of architects, interior designers, and 3D artists employs state-of-the-art tools to produce high-resolution renders, including exterior and interior visualizations, aerial views, walkthroughs, and more.",
      DetailingServices: "",
      DetailingServicesName: "",
      DetailingServicesSample: "",
      pdfsample:Archsample,
    },
  {
    categories: "Architectural",
    CoreServicesName: "3D Floor Plan",
    CoreServicesDetails: "<p><b>3D Floor Plan Services</b></p>",
    CoreServicesDetails0:
      "EDCAT is an Architectural and Engineering Outsourcing Company offering high-quality 3D Floor Plan Services to clients in the USA, UK, Australia, Canada, and Europe. We specialize in developing 3D floor plan units for residential and commercial setups. We assist our clients in transforming their 2D CAD plans into realistic 3D floor plans, featuring meticulous furniture placements, fixtures, flooring, and various props that give the floor plan a creative edge.",
    CoreServicesDetails1:
      "Our team consists of qualified architects, interior designers, and 3D renderers who transform conceptual designs into 3D plans, providing a realistic feel of the interior spaces of a building. We possess a combined skill set in software, creativity, and knowledge of aesthetics applicable across different countries. Our deliverables for 3D Floor Plan projects reflect the aesthetic display of colors, furniture, and space planning specific to each location.",
    CoreServicesDetails2:
      "With over a decade of experience in this domain, combined with our expertise in 3D Rendering Services, we have emerged as a leading provider of high-quality, cost-effective, and photorealistic Architectural 3D plans.",
      DetailingServices: "",
      Sample: Arch,
      DetailingServicesName: "",
      DetailingServicesSample: "",
      pdfsample:Archsample,
    },
  {
    categories: "Architectural",
    CoreServicesName: "Revit Family Creation",
    CoreServicesDetails: "<p><b>Revit Family Creation Services</b></p>",

    CoreServicesDetails1:
      "EDCAT is a BIM Outsourcing Company providing customized and ready-to-use Revit Family Creation Services globally. We cater to manufacturers, product designers, and BIM companies with ongoing requirements for architectural, structural, and MEP components. Our team creates standardized and parametric Revit families from 2D CAD drawings and PDFs, ensuring accurate dimensions and specifications.",
    CoreServicesDetails2:
      "With over a decade of experience in Revit Family Creation and BIM project handling, our Revit experts design tailored BIM elements that can seamlessly integrate into virtual design and construction models. Our proven track record with renowned construction and design companies has established us as a reliable partner for all BIM Object Creation Services.",
    CoreServicesDetails3:
      "We specialize in creating system, in-place, and loadable Revit families for various components, including architectural, structural, mechanical (HVAC), electrical, plumbing, and fire-fighting systems. These families can be utilized directly in BIM projects or as part of a library. Our Revit specialists focus on creating custom parametric or generic families that assist architects, engineers, builders, fabricators, and product manufacturers in designing, planning, and estimating costs for their projects.",
    Sample: Arch,
    DetailingServices:
      "<p><b>Our Custom Revit Family Creation Services</b></p>",
    BIMConversionServicesDetails1:
      "<p><b>Architectural BIM Family Creation<b><br/>We specialize in creating Revit families for a wide range of architectural elements, including doors and windows, walls, furniture, railings, staircases, casework, foundations, and entourage. Additionally, we build comprehensive libraries of architectural BIM objects that architects can easily modify to meet their specific project needs.</p>",
    BIMConversionServicesDetails2:
      "<p><bStructural Revit Content Creation<b><br/>Our team of engineers and BIM specialists excels in developing Structural Revit Families for essential components such as beams and columns, trusses and joists, stiffeners, corrugated roofs, fences, and structural connections (like base plates, mounting parts, and gusset plates). We also create customized steel sections to fit various design requirements.</p>",
    BIMConversionServicesDetails3:
      "<p><b>HVAC Revit Family Creation<b><br/>We provide tailored Revit Content Creation solutions for HVAC components, including duct hangers, air handling units (AHUs), fan coil units (FCUs), pipes (strainers, valves, hangers), fittings and valves, fire dampers, diffusers, registers, and grilles. Our HVAC families are designed to enhance your project’s efficiency and accuracy.</p>",
    BIMConversionServicesDetails4:
      "<p><b>Electrical BIM Object Creation<b><br/>Our team creates BIM objects for all electrical elements necessary for your projects, which can be used to develop a robust library or for direct application. This includes BIM content for switches, sockets, transformers, distribution boards, lighting fixtures, fire alarm devices, and more.</p>",
    BIMConversionServicesDetails5:
      "<p><b>Plumbing Revit Family Creation<b><br/>Our extensive portfolio of Plumbing families covers a variety of components, including pumps, pipes and fixtures, valves, washbasins, urinals, water closets, fittings, devices, gauges, and measuring devices. We ensure that these components meet industry standards and can be seamlessly integrated into your projects.</p>",
    BIMConversionServicesDetails6:
      "<p><b>Firefighting BIM Content Creation<b><br/>Our MEP engineers and BIM specialists are proficient in creating comprehensive libraries and models for firefighting BIM objects, including sprinklers, fire extinguishers, fire detection valves, alarm systems, fittings, and cabinets. These models can be customized and incorporated into projects as needed, ensuring compliance and safety.<br/>By choosing our Custom Revit Family Creation Services, you gain access to high-quality, tailored BIM solutions that enhance your project’s design and efficiency.</p>",
      DetailingServices: "",
      DetailingServicesName: "",
      DetailingServicesSample: "",
      pdfsample:Archsample,
    },
  {
    categories: "Architectural",
    CoreServicesName: "As Built Documentation",
    CoreServicesDetails: "<p><b>As-Built Documentation Services</b></p>",
    BIMConversionServicesDetails1:
      "<p><b>As-Built Drawing Services<b><br/>EDCAT Outsourcing Services provides qualitative and accurate As-Built Drawing Services. We are an Architectural and Engineering firm with years of experience. Our in-house team of architects, engineers, MEP Technicians, and BIM Modelers work tirelessly to deliver time-tested and efficient solutions for complex projects.</p>",
    BIMConversionServicesDetails2:
      "<p><b>As-Built Modeling Services<b><br/>As-Built Modeling Services are created once a project is completed for the purpose of documentation. These 2D Drawings or 3D Models reflect any modifications or adjustments made on-site. Our As-Built drawings provide significant insight into the architectural, structural, and MEP components utilized in the building. We ensure that these drawings are plotted according to industry and building standards and include in-depth specifications, such as marking the location, name, function, shape, size, and other details of all building elements.</p>",
    BIMConversionServicesDetails3:
      "<p><b>As-Built Drafting Services<b><br/>As-Built Drafting Services are utilized for surveys, maintenance, and review of existing buildings. They also aid in the renovation and restoration processes. Our services can be used for the construction and restoration of historical, residential, commercial, or industrial buildings.</p>",
    Sample: Arch,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:Archsample,
  },
  {
    categories: "MEP",
    CoreServicesName: "MEP BIM Services",
    CoreServicesDetails1: "At EDCAT, we specialize in delivering 3D MEP BIM Modeling Services for HVAC, Plumbing, Electrical, and Fire Protection systems. Our team of skilled BIM Modelers and Engineers creates highly detailed 3D models that represent MEP components such as ductwork, electrical fixtures, lighting, plumbing, drainage, and piping with precision.",
    CoreServicesDetails2: "Our BIM Coordination Services assist designers, architects, and engineers in identifying inter- or intra-discipline clashes, helping to save both time and money during construction. Using advanced BIM software such as Navisworks, Revit, ArchiCAD, and AutoCAD, we effectively detect and resolve clashes.",
    CoreServicesDetails3: "We offer standardized, code-compliant Coordination Drawings used for the onsite installation of MEP components during the construction phase. Our MEP Shop Drawing sets include detailed sections, elevations, layouts, and other necessary documentation for smooth execution.",
    CoreServicesDetails4:"EDCAT provides top-tier Clash Detection Solutions to identify any hard, soft, or workflow clashes between architectural, MEP, and structural elements. We also detect intra-discipline clashes within MEP sub-disciplines, such as between mechanical, electrical, and plumbing systems. By addressing these issues early, construction companies can avoid cost overruns and optimize project efficiency.",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>MEP BIM MODELING SERVICES</li><li>MEP BIM COORDINATION SERVICES</li><li>MEP SHOP DRAWING SERVICES</li><li>MEP CLASH DETECTION SERVICES</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },
  {
    categories: "MEP",
    CoreServicesName: "MEP BIM Services",
    CoreServicesDetails1: "EDCAT specializes in providing comprehensive MEP BIM services, delivering detailed MEP BIM models at the required Level of Detail (LOD) in line with the BIM execution plan. Since its inception, EDCAT has helped leading construction companies streamline their preconstruction processes with precise, data-driven BIM solutions",
    CoreServicesDetails2: "At EDCAT, our team of multidisciplinary experts, including architects, BIM specialists, and MEP engineers, excels in understanding the technical complexities involved in creating construction-ready BIM models. Our rigorous quality assurance processes ensure that the final 3D models comply with all relevant HVAC, Electrical, and Plumbing building codes. Additionally, the MEP shop drawings extracted from our BIM models are accurate and detailed, enabling seamless on-site installation of MEP components. These models enhance both the efficiency and quality of the building.",
    CoreServicesDetails3: "We also offer top-tier MEP BIM coordination and clash detection services to identify and resolve issues during the design phase, preventing costly delays later in the project. Our client-centric approach and efficient business practices have earned us a reputation as a trusted partner in BIM services.",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>MEP BIM MODELING SERVICES</li><li>MEP BIM COORDINATION SERVICES</li><li>MEP SHOP DRAWING SERVICES</li><li>MEP CLASH DETECTION SERVICES</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },  {
    categories: "MEP",
    CoreServicesName: "MEP BIM Coordination Services",
    CoreServicesDetails1: "EDCAT is a leading BIM Outsourcing Company offering comprehensive MEP BIM Coordination Services and BIM Clash Detection Services. With extensive experience, we have successfully completed thousands of projects and established lasting partnerships with construction companies worldwide.",
    CoreServicesDetails2: "We specialize in extracting precise and functional Shop Drawings from clash-free models that are detailed, accurate, and comply with international standards and codes. Our MEP BIM Coordination Services are tailored for residential, commercial, and industrial construction projects.",
    CoreServicesDetails3: "Led by a team of skilled engineers, architects, and MEP technicians proficient in software such as Autodesk Revit, AutoCAD MEP, and Navisworks, we deliver fast and accurate BIM Coordination Services. Our expertise extends to conducting constructability reviews, generating detailed clash reports, and holding effective coordination meetings with clients. Our deep domain knowledge and extensive experience enable us to resolve clashes efficiently during the preconstruction stage, ensuring seamless project execution.",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Modeling Services</li><li>Clash Detection Services</li><li>MEP BIM Coordination</li><li>MEP Shop Drawings</li><li>Revit Family Creation</li><li>Scan To BIM Services</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },
  {
    categories: "MEP",
    CoreServicesName: "Clash Detection Services",
    CoreServicesDetails1: "EDCAT is a BIM Service Provider offering top-tier Clash Detection Services. We integrate strategic planning, customer insights, and cutting-edge technology to deliver superior project results within quick turnaround times.",
    CoreServicesDetails2: "Construction projects are highly complex, requiring seamless coordination across architectural, structural, and engineering disciplines. Errors during the onsite building process can lead to significant cost implications. Our precise BIM Clash Detection Services empower engineers and architects to detect, assess, and address clashes during the design phase, ultimately saving time, resources, and money.",
    CoreServicesDetails3: "With a history of successful collaborations with leading construction companies worldwide, our extensive experience makes us a trusted provider of MEP Clash Detection services.",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Modeling Services</li><li>MEP BIM Services</li><li>MEP BIM Coordination</li><li>MEP Shop Drawings</li><li>Revit Family Creation</li><li>Point Cloud to BIM</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },
  {
    categories: "MEP",
    CoreServicesName: "MEP Shop Drawing Services",
    CoreServicesDetails1: "EDCAT has extensive experience in delivering high-quality and precise MEP Shop Drawing Services for over a decade at competitive rates. Our in-house team of skilled MEP Engineers is proficient in creating HVAC shop drawings tailored to meet each client’s specific requirements. We are committed to consistently delivering superior drawings and ensuring client satisfaction on every project we undertake.",
    CoreServicesDetails2: "Our main goal is to produce Coordination Drawings that enhance the overall performance of a building. Accurate coordinated drawings are essential for the profitability of any construction project. At EDCAT, we ensure that clashes are identified and resolved during the pre-construction phase, resulting in fully coordinated HVAC, electrical, and plumbing drawings.",
    CoreServicesDetails3: "MEP Shop Drawings typically represent the contractor’s or manufacturer’s detailed version of drawings that guide the fabrication process. These drawings are essential for producing pre-fabricated components in MEP installations, with a focus on the specific details needed for manufacturing. MEP Installation Drawings illustrate the typical installation process and are designed for the contractor’s installation team.",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>MEP BIM Services</li><li>Clash Detection Services</li><li>Revit Family Creation</li><li>Point Cloud to BIM</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },
  {
    categories: "MEP",
    CoreServicesName: "MEP Drafting Services",
    CoreServicesDetails1: "EDCAT is a leading CAD service provider specializing in MEP Drafting Services for clients worldwide. We collaborate with esteemed MEP contractors, engineering consultants, and construction companies to deliver complex and detailed MEP CAD drafting solutions. Our skilled team of MEP drafters and engineers brings over a decade of experience to a diverse range of MEP projects.",
    CoreServicesDetails2: "We thoroughly understand your project requirements, ensuring timely delivery with high precision and standardized output. Our expertise in global construction standards and design codes positions us as a reliable and sought-after MEP CAD drafting service provider. Outsourcing your MEP CAD services can be highly beneficial for countries such as the USA, Canada, the UK, and Europe.",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>CAD Services</li><li>MEP Shop Drawings</li><li>MEP BIM Services</li><li>Clash Detection Services</li><li>MEP BIM Coordination</li><li>BIM Services</li></ul>",
    DetailingServicesSample:"",
    pdfsample:MEP,
  },{
    categories: "CAD",
    CoreServicesName: "Drafting Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: Arch,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Consulting Services</li><li>BIM for Infrastructure</li><li>4D BIM Services</li><li>5D BIM Services</li><li>Cobie Services</li><li>As Built Services</li></ul>",
    DetailingServicesSample:"",
    pdfsample:Structuralpdf,
  },{
    categories: "CAD",
    CoreServicesName: "As-Built Drawing Services ",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: Arch,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>Architectural BIM Services</li><li>MEP BIM Services</li><li>Structural BIM Services</li><li>Quantity Takeoff</li><li>Cobie</li></ul>",
    DetailingServicesSample:"",
    pdfsample:Structuralpdfall,
  },{
    categories: "CAD",
    CoreServicesName: "MEP Shop Drawing Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: Arch,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>MEP BIM Services</li><li>MEP BIM Coordination</li><li>Clash Detection Services</li><li>Revit Family Creation</li><li>Point Cloud to BIM</li></ul>",
    DetailingServicesSample:"",
    pdfsample:Shop,
  },{
    categories: "BIM",
    CoreServicesName: "VDC and BIM SERVICES",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Consulting Services</li><li>BIM for Infrastructure</li><li>4D BIM Services</li><li>5D BIM Services</li><li>Cobie Services</li><li>As Built Services</li><li>Clash Detection Services</li><li>Steel Detailing Services</li><li>6D BIM Services</li><li>BIM Facility Management</li></ul>",
    DetailingServicesSample:"",
  },{
    categories: "BIM",
    CoreServicesName: "BIM Consulting Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Consulting Services</li><li>BIM for Infrastructure</li><li>4D BIM Services</li><li>5D BIM Services</li><li>Cobie Services</li><li>As Built Services</li><li>Clash Detection Services</li><li>Steel Detailing Services</li><li>6D BIM Services</li><li>BIM Facility Management</li></ul>",
    DetailingServicesSample:"",
  },{
    categories: "BIM",
    CoreServicesName: "Infrastructure and Civil",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>Structural BIM Services</li><li>Steel Detailing Services</li><li>Scan to BIM Services</li><li>Facility Management</li><li>4D BIM Services</li></ul>",
    DetailingServicesSample:"",
  },{
    categories: "BIM",
    CoreServicesName: "Scan to BIM Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Consulting Services</li><li>BIM for Infrastructure</li><li>4D BIM Services</li><li>5D BIM Services</li><li>Cobie Services</li><li>As Built Services</li><li>Clash Detection Services</li><li>Steel Detailing Services</li><li>6D BIM Services</li><li>BIM Facility Management</li></ul>",
    DetailingServicesSample:"",
  },
  {
    categories: "BIM",
    CoreServicesName: "MEP BIM Coordination Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>Clash Detection Services</li><li>MEP BIM Services</li><li>MEP Shop Drawings</li><li>Revit Family Creation</li><li>Scan to BIM Services</li></ul>",
    DetailingServicesSample:"",
  },  {
    categories: "BIM",
    CoreServicesName: "Clash Detection Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Modeling Services</li><li>MEP BIM Services</li><li>MEP BIM Coordination</li><li>MEP Shop Drawings</li><li>Revit Family Creation</li><li>Point Cloud to BIM</li></ul>",
    DetailingServicesSample:"",
  },
  {
    categories: "BIM",
    CoreServicesName: "Revit Family Creation Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>Architectural BIM Services</li><li>MEP BIM Services</li><li>Structural BIM Services</li><li>BIM Services</li><li>BIM Coordination Services</li><li>Clash Detection Services</li></ul>",
    DetailingServicesSample:"",
  },  {
    categories: "BIM",
    CoreServicesName: "Construction Scheduling Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>Architectural BIM Services</li><li>Structural BIM Services</li><li>Facility Management</li><li>Quantity Takeoff</li></ul>",
    DetailingServicesSample:"",
  },
  {
    categories: "BIM",
    CoreServicesName: "Cost Estimation & Quantity Takeoff",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Modeling Services</li><li>Architectural BIM Services</li><li>Structural BIM Services</li><li>MEP BIM Services</li><li>Cobie</li><li>4D Scheduling</li></ul>",
    DetailingServicesSample:"",
  },{
    categories: "BIM",
    CoreServicesName: "COBie Modeling Services",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: MEPPic ,
    DetailingServicesSample: "",
    DetailingServicesName:"<ul><li>BIM Services</li><li>Architectural BIM Services</li><li>Structural BIM Services</li><li>MEP BIM Services</li><li>Quantity Takeoff</li><li>4D Scheduling</li></ul>",
    DetailingServicesSample:"",
  },

  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "concrete-color-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample11,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  },
  
 
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "lumber-color-plan",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample15,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "marked",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample16,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "metals-color-plan-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample17,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "mosanry-color-plan-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample18,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }

  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "painting-color-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample19,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "residential-color-plan",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample20,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "plumbing-color-plan-samples",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample21,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }  ,

  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "commercial-samples-summary",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample10,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  },
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "drywall-color-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample12,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "electrical-color-plan-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample13,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "exterior-finishes-color-sample",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample14,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  }
  ,
  {
    categories: "Estimation & Material Take-off",
    CoreServicesName: "multifamily-appartments-color-plan",
    CoreServicesDetails1: "",
    CoreServicesDetails2: "",
    CoreServicesDetails3: "",
    Sample: picSample22,
    DetailingServices: "",
    DetailingServicesName: "",
    DetailingServicesSample: "",
    pdfsample:""
  },
]




export { Structural };