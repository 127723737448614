import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./pic/1x/LOGO.png";
import "./css/navbar.css";
import Navbar2 from "./ServiceNav";
import { scrollToTop } from "./UtilityFunction/scrollToTop";

export default function Navbar() {
  const [showServices, setShowServices] = useState(false);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const closeNavbar = () => {
    setIsCollapsed(false);
  };

  const handleMouseEnter = () => {
    setShowServices(true);
  };

  const handleMouseLeave = () => {
    setShowServices(false);
  };

  const handleServiceClick = () => {
    navigate("/service");
    closeNavbar();
    setShowServices(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand p-2" to="/">
            <img src={logo} style={{ width: "50px" }} alt="Logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {showServices && (
            <div
             onMouseLeave={handleMouseLeave}
              className="position-absolute element"
              style={{ top: "80%", left: "0", width: "100%" }}
            >
              <Navbar2 />
            </div>
          )}

         
          <div
            className={`collapse navbar-collapse ${isCollapsed ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto me-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    scrollToTop();
                    closeNavbar(); 
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item ms-4">
                <Link
                  className="nav-link"
                  to="/about"
                  onClick={() => {
                    scrollToTop();
                    closeNavbar(); 
                  }}
                >
                  About
                </Link>
              </li>
              <li
                className="nav-item ms-4 position-relative"
                onMouseEnter={handleMouseEnter}
               
                
                onClick={() => {
                  scrollToTop();
                  closeNavbar(); 
                  handleServiceClick()
                }}
                // style={{ cursor: "pointer" }}
              >
                <span className="nav-link">Services</span>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link ms-4"
                  to="/contact"
                  onClick={() => {
                    scrollToTop();
                    closeNavbar(); 
                  }}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
