import React from 'react';
import { coreServices } from '../data.js';
import { Link } from 'react-router-dom';
import { scrollToTop } from "../UtilityFunction/scrollToTop.js";

const CoreServices = () => {
    return (
        <div style={{ width: '95%' }}>
            <h2 className="text-center  mt-5 mb-3 text-e1">Core Services</h2>
            <div className="row justify-content-center ps-5">
                {coreServices.map((expertise, index) => (
                    <div
                        key={index}
                        className="col-lg-3 col-md-4 col-sm-12 border border-2 border-secondary rounded rounded-4 p-4 m-5 "
                    >
                        <Link
                            to={`/WhatWeDo/${encodeURIComponent(expertise.name)}`}
                            className="mt-3 mb-3 text-e2 CoreServicesh4"
                            onClick={scrollToTop}
                        >
                            {expertise.name}
                        </Link>
                        <ul className="list-group mt-3">
                            {expertise.detail.match(/<li.*?>(.*?)<\/li>/g)?.map((item, itemIndex) => {
                                const itemName = item.replace(/<\/?li.*?>/g, '').trim(); // Extract text from list item
                                return (
                                    <li
                                        key={itemIndex}
                                        className="list-group-item text-center"
                                    >
                                        <Link
                                            to={`/AllService/${encodeURIComponent(itemName)}`}
                                            className="text-ep1"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {itemName}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CoreServices;
