import './App.css';
import Navbar from './components/navbar';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './components/home';
import Service from './components/service';
import About from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import AllService from './components/About/sevice/AllService';
import SeviceDetails from './components/About/sevice/SeviceDetails';
import WhatWeDo from './components/About/sevice/WhatWeDo';
import Veiwpdf from './components/About/sevice/Veiwpdf';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Navbar />
          <Home />
          <Footer />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <Navbar />
          <About />
          <Footer />
        </>
      ),
    },
    {
      path: "/service",
      element: (
        <>
          <Navbar />
          <Service />
          <Footer />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <Navbar />
          <Contact />
          <Footer />
        </>
      ),
    },
    {
      path: "/AllService/:itemName",
      element: (
        <>
          <Navbar />
          <AllService />
          <SeviceDetails />
          <Footer />
        </>
      ),
    },
    {
      path: "/WhatWeDo/:categories",
      element: (
        <>
          <Navbar />
          <WhatWeDo />
          <Footer />
        </>
      ),
    },
    {
      path: "/Veiwpdf/:categories",
      element: (
        <>
        
         <Veiwpdf/>
          
        </>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
