import React, { useState } from "react";
import { VDM_BIM_CAD, EngineeringConsulting } from "./data.js";
import { Link } from "react-router-dom";
import logo from "./pic/1x/LOGO.png";
import "./css/navbar.css";

export default function Navbar2() {
  const defaultService = VDM_BIM_CAD.find((service) => service.name === "Structural");

  const [selectedCategory, setSelectedCategory] = useState(VDM_BIM_CAD);
  const [selectedService, setSelectedService] = useState(defaultService);
  const [showServices, setShowServices] = useState(false);
  const [showServiceDetail, setShowServiceDetail] = useState(false);

  const handleCategoryHover = (category) => {
    setSelectedCategory(category);
    const defaultServiceForCategory = category[0] || null;
    setSelectedService(defaultServiceForCategory);
    setShowServices(true);
    setShowServiceDetail(false);
  };

  const handleServiceHover = (service) => {
    setSelectedService(service);
    setShowServiceDetail(true);
  };

  const handleMouseLeave = () => {
    setShowServices(false);
    setShowServiceDetail(false);
  };

  return (
    <div
      className="dropdown-menu show mt-2 bg-light d-flex"
      style={{ width: "100vw" }}
      onMouseLeave={handleMouseLeave}
    >
      {/* Category Section */}
      <ul className="col-2" type="none">
        <h5 className="nav-h5">What We Do</h5>
        <li
          className="dropdown-item nav-link2"
          style={{ padding: 0, cursor: "pointer" }}
          onMouseEnter={() => handleCategoryHover(VDM_BIM_CAD)}
        >
          VDM/BIM/CAD
        </li>
        <li
          className="dropdown-item nav-link2"
          style={{ padding: 0, cursor: "pointer" }}
          onMouseEnter={() => handleCategoryHover(EngineeringConsulting)}
        >
          Engineering Consulting
        </li>
      </ul>

      {/* Services Section */}
      {showServices && (
        <ul className="col-3" type="none">
          <h5 className="nav-h5">Service</h5>
          {selectedCategory.map((service, index) => (
            <li key={index}>
              <Link
                className="dropdown-item nav-link2"
      
                onMouseEnter={() => handleServiceHover(service)}
                style={{ padding: 0 }}
                // to={`/WhatWeDo/${selectedService.name}`}
              >
                {service.name}
              </Link>
            </li>
          ))}
        </ul>
      )}

      {/* Selected Service Detail Section */}
      {showServiceDetail && selectedService && (
        <div className="col-2">
          <Link  style={{ textDecoration: "none" }} className="nav-h5" to={`/WhatWeDo/${selectedService.name}`}>{selectedService.name}</Link>
          <ul className="list-group">
            {selectedService.detail.match(/<li>(.*?)<\/li>/g)?.map((item, itemIndex) => {
              const itemName = item.replace(/<\/?li.*?>/g, "").trim();
              return (
                <li key={itemIndex} type="none">
                  <Link
              
                    className="text-ep1"
                    style={{ textDecoration: "none" }}
                  >
                    {itemName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {/* Static Information Section */}
      <div className="col-3 m-auto">
        <h5 className="nav-h5">Partners in Engineering Excellence</h5>
        <p className="nav-link3">
          EDCAT is a trusted engineering service partner to the construction
          industry. Whether you are a builder, a general contractor, or an
          architect, EDCAT can fulfill your project requirements with tailored
          and customized solutions.
        </p>
      </div>

      {/* Logo Section */}
      <div className="m-auto">
        <Link className="navbar-brand p-2 col-2 d-flex justify-content-center" to="/">
          <img src={logo} style={{ width: "50px" }} alt="Logo" />
        </Link>
      </div>
    </div>
  );
}
