import React from 'react';
import { Link } from "react-router-dom";
import imge1 from "./pic/EXPERTISE/tos-home-architectural-bim-services-image.jpg";
import imge2 from "./pic/EXPERTISE/tos-home-architectural-construction-drawings-image.png";
import imge5 from "./pic/EXPERTISE/bim-services-banner-image.jpg";
import imge6 from "./pic/EXPERTISE/scan-to-bim-banner-image-1.jpg";
import imge7 from "./pic/EXPERTISE/tos-home-mep-bim-services-image.jpg";
import imge8 from "./pic/EXPERTISE/tos-home-revit-family-creation-image.jpg";
import whyimg1 from './pic/About/i.jpg';
import whyimg2 from './pic/About/o.jpg';
import whyimg3 from './pic/About/r.jpg';
import Industrial from './pic/Key market/k1.png';
import Commercial from './pic/Key market/k2.png';
import Petrochemicals from './pic/Key market/k3.png';
import Residential from './pic/Key market/k4.png';
import pic1 from "./pic/About/pic1.png";
import pic2 from "./pic/About/pic2.png";
import pic3 from "./pic/About/pic3.png";
import pic4 from "./pic/About/pic4.png";
import SoftwarePic1 from './pic/Home/ezgif-6-16396091b6.jpg'
import SoftwarePic2 from './pic/Home/Autodesk_BIM-Collaborate-Pro.jpg'
import SoftwarePic3 from './pic/Home/Autodesk_Navisworks-Manage.jpg'
import SoftwarePic4 from './pic/Home/ReCap-Pro-2023-lockup-Blk-OL-ADSK-No-Year-Stacked.jpg'
import SoftwarePic5 from './pic/Home/autodesk-autocad-2024-logo.jpg'
import SoftwarePic6 from './pic/Home/ezgif-6-973a1b2308.jpg'



const coreServices = [
    {
        name: "Structural",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Steel Detailing Services</li><li class='list-group-item'>Rebar Detailing Services</li><li class='list-group-item'>Precast Detailing</li><li class='list-group-item'>Structural CAD Drafting</li><li class='list-group-item'>Steel Shop Drawings Services</li><li class='list-group-item'>Structural BIM Services</li><li class='list-group-item'>As-Built Drawing Services</li></ul>",

    },
    {
        name: "Architectural",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Architectural BIM Services</li><li class='list-group-item'>Scan To BIM Services</li><li class='list-group-item'>Architectural CAD Drafting</li><li class='list-group-item'>Construction Documentation</li><li class='list-group-item'>3D Rendering Services</li><li class='list-group-item'>3D Floor Plan</li><li class='list-group-item'>Revit Family Creation</li><li class='list-group-item'>CAD Services</li><li class='list-group-item'>As Built Documentation</li></ul>",
      
    },
    {
        name: "MEP",
        detail:
            "<ul class='list-group'><li class='list-group-item'>MEP BIM Services</li><li class='list-group-item'>MEP BIM Cordination</li><li class='list-group-item'>Clash Detection</li><li class='list-group-item'>MEP Shop Drawings</li><li class='list-group-item'>MEP CAD Drafting</li></ul>",
    },
    {
        name: "BIM",
        detail:
            "<ul class='list-group'><li class='list-group-item'>BIM Modeling</li><li class='list-group-item'>BIM Consulting Services</li><li class='list-group-item'>BIM For Infrastructure</li><li class='list-group-item'>Point Cloud To BIM</li><li class='list-group-item'>BIM Coordination</li><li class='list-group-item'>BIM Clash Detection</li><li class='list-group-item'>BIM Content Creation</li><li class='list-group-item'>BIM Shop Drawings</li><li class='list-group-item'>4D BIM Services</li><li class='list-group-item'>Quantity Takeoff</li><li class='list-group-item'>COBie</li><li class='list-group-item'>6D BIM Services</li><li class='list-group-item'>Facility Management</li></ul>",
   
    },
    {
        name: "Estimation & Material Take-off",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Commercial Estimating</li><li class='list-group-item'>Residential Estimating</li><li class='list-group-item'>Industrial Estimating</li><li class='list-group-item'>Building Estimating</li><li class='list-group-item'>Freelance Estimating</li><li class='list-group-item'>Blueprint Estimating</li><li class='list-group-item'>Electrical Estimating</li><li class='list-group-item'>Outsourcing Estimating</li></ul>",
      
    },
    // {
    //     name: "Other Services",
    //     detail:
    //         "<ul class='list-group'><li class='list-group-item'>Industrial Engineering</li><li class='list-group-item'>Solar panel detailing</li></ul>",
    //     img: Structural,
    // },
     {
        name: "CAD",
        detail:
            "<ul class='list-group'><li class='list-group-item'>Drafting Services</li><li class='list-group-item'>As Built Drawing Services</li><li class='list-group-item'>Shop Drawings Services</li></ul>",
     
    },
];

const ExpertiseData = [
    {
        name: "Structural",
        detail:
            "EDCAT engineers with deep industry knowledge and who are well trained in all projects. We understand and visualize the real time fabrication and erection complexity and rectify it on time too. Our focus is to create drawings in such a way that the fabricators can easily handle the drawings and complete the job.",
        
        img: imge6,
        button: <Link to="../EXPERTISE/design.js">Learn More</Link>,
    },
    {
        name: "Architectural",
        detail:
            "Miscellaneous steel detailing demands a lot of self-decision while working on it, especially in modelling side. Also, this part of detailing demands good technical knowledge as well. Our well experienced team does each job precisely and through best method.",
       
        img: imge1,
        button: <Link to="../EXPERTISE/design.js">Learn More</Link>,
    },
    {
        name: "MEP",
        detail:
            "Our well experienced team has handled many plate work jobs including large round and rectangular ducts. We have experience of handling Transitions, Concentric cones, Eccentric cones, Chutes, etc. Our services include delivering unfolded DXF files even for transition plates or eccentric cones.",
        
            img: imge7,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "BIM",
        detail:
            "Cronus steel detailing offers connection design services to clients through our engineering partners in USA and Canada. In this, both design and detailing come under one roof and thereby it benefits in improved schedule. Also, we offer PE stamping (If contract required).",
        img: imge5,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "Estimation & Material Take-off",
        detail:
            "Warehouse Shade Detailing requires significant independent decision-making, particularly during the modeling phase. This aspect of detailing also necessitates strong technical expertise. Our highly experienced team performs each task with precision, utilizing the best methods available.",
            img: imge2,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
    {
        name: "CAD",
        detail:
            "We have a quick estimation procedure that includes 3D modelling, Material Reports, Complexity study, Material take-off and Man Hour Consumption calculation. On customer's requirement, we can deliver material take off as well.",
           
            img: imge8,
        button: <Link to="../EXPERTISE/SteelDetailing">Learn More</Link>,
    },
];

const Exp = [
    {
      Headers: "OIL & GAS & POWER",
      Description:
        "<ul><li>Static & Vibrating Equipment Foundations</li><li>Pipe racks/Pipe Bridges</li><li>Process structures like Cooling Towers, Powerhouse </li><li>Equipment supporting steel/concrete structures</li><li>Chimney/Stacks foundation</li><li>Pits, Pond Dike wall</li><li>Steel Tank Roof structure & Foundations</li></ul>",
      img: whyimg1,
    },
    {
      Headers: "INDUSTRIAL",
      Description:
        "<ul><li>Industrial Sheds</li><li>Process Buildings</li><li>Conveyor System</li></ul>",
      img: whyimg2,
    },
    {
      Headers: "RESIDENTIAL",
      Description:
        "<ul><li>COST ESTIMATION</li><li>BOQ/MATERIAL TAKE-OFF</li></ul>",
      img: whyimg3,
    },
  ];

  const Markets = [{
    name: "Industrial",
    img: Industrial
}, {
    name: "Commercial",
    img: Commercial
}, {
    name: "Oil & Gass / Petrochemicals",
    img: Petrochemicals
}, {
    name: "Residential",
    img: Residential
}];
const Approachdata = [
    {
      img1: pic1,
      Header: "Understanding Client Requirements:",
      description: "We recognize that each client and project demand a unique approach, often requiring a new set of skills and technologies. We dedicate the necessary time to thoroughly understand the project's objectives.",
    },
    {
      img1: pic2,
      Header: "Delivering Solutions:",
      description: "To achieve project goals, our engineers adopt the most appropriate methods, consistently surpassing expectations. Our work follows a system-driven process, incorporating the latest BIM industry methods, ensuring projects are delivered on time and of the highest quality.",
    },
    {
      img1: pic3,
      Header: "Continuous Improvement:",
      description: "Led by highly capable and seasoned project managers, our engineering team continuously learns, researches, and updates their skills to meet the dynamic demands of the AEC industry. Systematic knowledge sharing and perfection of our work processes are ongoing practices at EDCAT. With every project, we ensure there is always room for innovation.",
    },
    {
      img1: pic4,
      Header: "Our Promise:",
      description: "Client satisfaction is our guaranteed promise, and we measure our success by the success of our clients. While we take pride in our past achievements, we view them as milestones in our ongoing pursuit of excellence.",
    },
  ];
  const keyValues = [
    {
        key: "Customer Satisfaction:",
        describe: "Consistently surpassing client expectations."
    },
    {
        key: "Standards:",
        describe: "Establishing exemplary standards in all our business dealings."
    },
    {
        key: "Integrity:",
        describe: "Maintaining sincerity, ethics, and transparency in all transactions."
    },
    {
        key: "Fairness:",
        describe: "Treating all clients equally, from large corporations to small businesses."
    },
    {
        key: "Technology Update:",
        describe: "Continuously updating our skills and technology to stay at the forefront of the industry."
    }
];
const VDM_BIM_CAD = [
    {
      name: "Structural",
      detail: "<ul type='none' class='p-0'><li>Steel Detailing</li><li>Rebar Detailing</li><li>Shop Drawing</li><li>Connection Design</li><li>Precast Panel Detailing</li><li>Structural 3D Modeling</li><li>Structural 2D Drafting</li></ul>",
      detail1:'STRUCTURAL DETAILING SERVICES',
      detail2:'The team of Structural Engineers at EDCAT, utilizing the industry’s latest technologies, ensures quick turnaround times, top quality, and cost-effectiveness. EDCAT operates as an extension of your team, seamlessly integrating with your workflow.',  
      detail3:'EDCAT has successfully completed numerous structural detailing projects for clients in the commercial and industrial sectors across North America, Canada, Australia, New Zealand, and the Middle East.',  
      detail4:"EDCAT offers a comprehensive range of BIM services, including 3D modeling, rebar and precast detailing, shop drawings, construction documentation, and quantity estimation. Our experienced steel detailers are skilled in using AutoCAD, Tekla, Advance Steel, and Revit.",
      detail5:"Whether your project is industrial, commercial, infrastructural, or residential, our expert team will provide complete, accurate, and timely drafting and modeling services. EDCAT is committed to customer satisfaction.",
    },
    {
      name: "Architectural",
      detail: "<ul type='none' class='p-0'><li>Architectural BIM Services</li><li>Scan To BIM Services</li><li>Architectural CAD Drafting</li><li>Construction Documentation</li><li>3D Rendering Services</li><li>3D Floor Plan</li><li>Revit Family Creation</li><li>CAD Services</li><li>As Built Documentation</li></ul>",
      detail1:'ARCHITECTURAL ENGINEERING SERVICES',
      detail2:'EDCAT offers comprehensive VDC/BIM/CAD engineering services in architecture, including 3D modeling, BIM, construction documentation, 2D drafting and detailing, CAD conversion, and point cloud solutions. Our expertise in architectural modeling, combined with our highly skilled team, ensures that every deliverable is both exceptionally accurate and competitively priced. To further enhance quality, we have a rigorous and refined Quality Control (QC) process in place. We prioritize understanding client standards thoroughly and fostering strong working relationships.',
      detail3:'Additionally, EDCAT provides 3D architectural and structural modeling to support MEP coordination. This modeling identifies and resolves clashes and collisions among various services before construction, resulting in significant time and cost savings.',
      detail4:"",
      detail5:"",
    },
    {
      name: "MEP",
      detail: "<ul type='none' class='p-0'><li>MEP BIM Modeling</li><li>MEP BIM Coordination</li><li>Clash Detection</li><li>MEP Shop Drawings</li><li>MEP CAD Drafting</li><li>Point Cloud Modeling</li><li>Revit Family Creation</li><li>Drafting Services</li><li>As Built Modeling</li></ul>",
      detail1:'MEP ENGINEERING SERVICES',   
      detail2:'EDCAT provides comprehensive solutions for all your MEP Building Information Modeling (BIM) needs. We position ourselves at the forefront of the AEC industry with a skilled team of MEP/HVAC engineers boasting over 150 years of combined experience. Our expertise in delivering MEP BIM services spans various sectors, including educational institutions, healthcare facilities, plants, residential, commercial, and industrial buildings. Leveraging our extensive experience gained from collaboration with HVAC/MEP consultants, contractors, fabricators, and manufacturers worldwide over the past decade, we ensure top-quality MEP/HVAC BIM services that strictly comply with international standards set by ASHRAE, SMACNA, NFPA, and IEEE.',  
      detail3:'',   
      detail4:"",
      detail5:"",
    },
    {
      name: "BIM",
      detail: "<ul type='none' class='p-0'><li>BIM Modeling</li><li>BIM Consulting Services</li><li>BIM For Infrastructure</li><li>Point Cloud To BIM</li><li>BIM Coordination</li><li>BIM Clash Detection</li><li>BIM Content Creation</li><li>BIM Shop Drawings</li><li>4D BIM Services</li><li>Quantity Takeoff</li><li>COBie</li><li>6D BIM Services</li><li>Facility Management</li></ul>",
      detail1:"BIM",
      detail2:'EDCAT has established itself as a leading name in the AEC (Architecture, Engineering, and Construction) industry, celebrated for delivering top-tier, innovative BIM (Building Information Modeling) services. Our specialized BIM offerings serve clients in the United States, the United Kingdom, Australia, and the United Arab Emirates. EDCAT is adept at aiding construction companies, general and specialty contractors, architects, engineers, and building owners in seamlessly integrating and maximizing the advantages of advanced BIM technology, all within the defined timelines, standards, and budget constraints. This approach not only ensures successful BIM implementation but also guarantees the highest return on investment for our valued clients.',
      detail3:"From the initial project stages to final demolition, we provide globally recognized, collaborative BIM solutions with a wide range of LOD (Level of Development) options, including 100, 200, 300, 400, and 500. Our team of over 250 skilled professionals with extensive experience in Architectural, Structural, Façade, and MEP domains is fully equipped to handle the complexities of any project, anywhere in the world. Choose EDCAT for unmatched BIM services, tailored to meet the unique demands of your project, ensuring efficiency, precision, and success.",
      detail4:"",
      detail5:"",
    },
    {
      name: "Estimation & Material Take-off",
      detail: "<ul type='none' class='p-0'><li>Commercial Estimating</li><li>Residential Estimating</li><li>Industrial Estimating</li><li>Building Estimating</li><li>Freelance Estimating</li><li>Blueprint Estimating</li><li>Electrical Estimating</li><li>Outsourcing Estimating</li></ul>",
      detail1:'Estimation & Material Take-off SERVICES',
      detail2:'At EDCAT, we specialize in providing precise Estimation and Material Take-off services tailored to meet the unique needs of construction projects. Our expert team utilizes advanced methodologies and tools to deliver accurate cost estimates and detailed material lists, ensuring that clients can make informed decisions throughout the project lifecycle.',  
      detail3:'With extensive experience across various sectors, including residential, commercial, and industrial construction, we understand the complexities involved in each project. Our process involves thorough analysis and assessment of project specifications, drawings, and documentation to identify all necessary materials and their quantities.',  
      detail4:"By maintaining strong relationships with suppliers and leveraging our knowledge of current market trends, we provide competitive pricing and insights that help optimize project budgets. EDCAT's commitment to accuracy and efficiency ensures that our clients receive reliable estimates and material take-offs, facilitating smoother project execution and minimizing the risk of unexpected costs.",
      detail5:"",
    },
    {
      name: "CAD",
      detail:
          "<ul><li>Drafting Services</li><li>As Built Drawing Services</li><li>Shop Drawings Services</li></ul>",
          detail1:'CAD SERVICES',
          detail2:'EDCAT specializes in delivering high-quality Computer-Aided Design (CAD) services tailored to meet the unique needs of construction companies, general contractors, and architects. Our expert team utilizes advanced CAD software to produce detailed drawings and designs across various disciplines, including architecture, structural engineering, and MEP systems.',  
          detail3:'With a commitment to precision and efficiency, we offer a range of CAD services, including 2D drafting, 3D modeling, and design visualization. Our experienced professionals collaborate closely with clients to ensure that all project specifications are met, enabling seamless integration into existing workflows.',  
          detail4:"At EDCAT, we prioritize quality assurance and adhere to industry standards, ensuring that all deliverables are accurate and compliant. By leveraging our CAD expertise, we help clients streamline their project timelines, reduce costs, and enhance overall productivity, paving the way for successful project outcomes.",
          detail5:"",
  },
  ];
  const EngineeringConsulting = [
    {
      name: "Civil Engineering Services",
      detail: "<ul type='none' class='p-0'><li>Designs and Calculations</li><li>Engineering Work Packages</li><li>Engineering Compliance Drawings</li><li>Engineering Check and Code Compliance</li><li>Cost Estimates</li></ul>",
    },
    {
      name: "Structural Engineering Services",
      detail: "<ul type='none' class='p-0'><li>Structural Design</li><li>Structural 3D mode</li><li>Dynamic analysis of equipment foundation</li><li>Work Packages</li></ul>",
    },
    {
      name: "Drafting and Design Engineering Consultants",
      detail: "<ul type='none' class='p-0'><li>Civil Structural</li></ul>",
    },
  ];

const Projects = [{
  name:'Years Experience',
  number:'10+'
},{
  name:'Countries Served',
  number:'5+'
},{
  name:'Happy Clients',
  number:'50+'
},{
  name:'Projects Delivered',
  number:'500+'
},]

const SoftwarePic = [{
  img: SoftwarePic1,
},{
  img: SoftwarePic2,
},{
  img: SoftwarePic3,
},{
  img: SoftwarePic4,
},{
  img: SoftwarePic5,
},{
  img: SoftwarePic6,
},]

export { ExpertiseData, coreServices, Exp, Markets , Approachdata, keyValues,VDM_BIM_CAD,EngineeringConsulting,Projects,SoftwarePic };
