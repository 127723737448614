import React from "react";

import '../css/Mission.css'
import { Approachdata } from '../data.js';
export default function Approach() {
  
  return (
    <div >
      <h1 className="text-center m-5" style={{color:'#ff7703'}}>Our Approach</h1>
      <div className="row m-5 d-flex justify-content-center">
      {Approachdata.map((Approach, index) => (
        <div key={index} className="col border border-2 rounded round-5 p-3 m-3">
          <img src={Approach.img1} alt="Approach" className="ms-auto me-auto d-flex justify-content-center p-5 imgApproach" />
          <h5 className="text-center" style={{color:'#ff7703'}}>{Approach.Header}</h5>
          <p className="text-center textp1">{Approach.description}</p>
        </div>
      ))}
    </div>
    </div>
  );
}
