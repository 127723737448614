import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Structural } from '../../CoreServices';
import Contect from '../../contact';
import Navbar from '../../navbar';
import Footer from "../../footer";

export default function Viewpdf() {
    const { categories } = useParams();
    const navigate = useNavigate();

    // Filter the Structural services by category
    const filteredServices = Structural.filter(
        (item) => item.categories === categories
    );

    // Get the PDF sample if available
    const pdfsample = filteredServices.length > 0 ? filteredServices[0].pdfsample : '';

    // Handle PDF not found - navigate to another route if needed
    const onPdfNotFound = () => {
        navigate('/service');
    };

    return (
        <div>
            {pdfsample ? (
                <iframe
                    src={pdfsample}
                    style={{ width: "100%", height: "100vh" }}
                    title="PDF Viewer"
                ></iframe>
            ) : (
                <>
                    <Navbar />
                    <Contect />
                    <Footer />
                </>
            )}
        </div>
    );
}
