import React from 'react';
import '../css/sevice.css';
import '../css/Experience.css';
import { useParams } from 'react-router-dom';
import { VDM_BIM_CAD } from "../data";

export default function DetailForAllServce() {
    const { categories } = useParams();

    const filteredServices = VDM_BIM_CAD.filter(
        (item) => item.name === categories 
    );

    // Check if there are any filtered services
    if (filteredServices.length === 0) {
        return <div>No services found for this category.</div>;
    }

    // Get the details from the first filtered service
    const service = filteredServices[0];
    const { detail1, detail2, detail3, detail4, detail5 } = service; 

    return (
        <div className="container pt-5">
            <h1 className="text1 mainseviceh12">
                {detail1}
            </h1>

            <div
                className="custom-bar mt-3 rounded rounded-4 me-1"
                style={{
                    width: '20%',
                    height: '4px',
                    backgroundColor: '#ed1b24',
                }}
            ></div>

            <p className="mt-3 servicetextp1">
                {detail2}
            </p>
            <p className="mt-3 servicetextp1">
                {detail3}
            </p>
            <p className="mt-3 servicetextp1">
                {detail4}
            </p>
            <p className="mt-3 servicetextp1">
                {detail5}
            </p>
        </div>
    );
}
