import React from 'react'
import { Projects } from '../../data'; 
export default function project() {
  return (
    <div className="row PCP text-center p-5">
    {Projects.map((project, index) => (
      <div className="col p-5 ms-3 rounded rounded-3 mt-2" style={{boxShadow:'0 2px 10px rgba(0, 0, 0, 0.2)',}} key={index}>
        <h3 className="numbers text1">{project.number}</h3>
        <h5>{project.name}</h5>
      </div>
    ))}
  </div>
  )
}
