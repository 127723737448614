import React from 'react';

import '../css/sevice.css';
import '../css/Experience.css';

export default function Service() {
  

  return (
    <div className="container pt-5">
      <h1 className="text1 mainseviceh12">
        CAD and BIM Service Provider offering comprehensive preconstruction solutions
      </h1>

 
      <div
        className="custom-bar mt-3 rounded rounded-4 me-1"
       
        style={{
          width: '20%',        
          height: '4px',
          backgroundColor: '#ed1b24',
        }}
      ></div>

      <p className="mt-3 servicetextp1">
        EDACT is a global leader providing a full range of VDM/BIM/CAD consulting
        and engineering services for the AEC industry. Our services cover all project
        stages, from design to facility management. We assist architects and consultants
        by managing design upgrades and documentation. Our engineers collaborate with
        design engineers, general contractors, and trade contractors to ensure smooth
        project execution. We also offer standalone detailing services for architectural
        and structural designs, including shop drawings for offsite fabrication of precast
        and steel members. Our proactive approach helps clients save time and money by
        identifying issues early in the project lifecycle. We focus on delivering tailored
        services to meet project goals and ensure optimal results.
      </p>
    </div>
  );
}
