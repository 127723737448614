import React from 'react';
import { SoftwarePic } from '../data';
import "../css/About.css"

export default function SoftwareUse() {
  return (
    <div className='row w-100 p-5'>
      {SoftwarePic.map((item, index) => (
        <div key={index}  className="col-lg-2 col-md-4 col-sm-6 mt-auto mb-auto ms-auto me-auto p-5"  >
          <img  src={item.img} alt={`Software ${index}`} style={{maxWidth:'100%',height:'auto'}}/>
        </div>
      ))}
      <p className='text-ep1'>International Building Code (IBC), International Residential Code (IRC), International Zoning Code (IZC) and other building standards & codes.</p>
    </div>

  );
}
