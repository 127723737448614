import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Structural } from '../../CoreServices';
import Service from '../DetailForAllServce';
import Project from './project';



export default function WhatWeDo() {
  const { categories } = useParams();

  const handleOpen = () => {
    window.open(`/Veiwpdf/${categories}`, '_blank'); 
  };


  const filteredServices = Structural.filter(
    (item) => item.categories === categories
  );

  // Return a message if no services are found
  if (filteredServices.length === 0) {
    return <h2 className="text-center mt-5">Service not found!</h2>;
  }

  return (
    <div className="main">
      <div className="mt-3 pt-1">
        <div className="aboutHeader mt-5">
          <div
            className="container d-flex justify-content-center align-items-center h-100 pt-3"
            style={{ margin: 'auto' }}
          >
            <h1 className="text-center textcolors" style={{ color: 'white', position: 'relative' }}>
              {categories}
            </h1>
          </div>
        </div>
      </div>

      <Service />
      <Project />

      {/* Structural Services Section */}
      <div className="page mt-4 p-3">
        <h1 className="ms-3 p-3">{categories}</h1>
        <div className="allCardDiv row w-90 m-2">
          {filteredServices.map((item, index) => (
            <div className="loop col-lg-3 col-md-6 col-sm-12 pb-5" key={index}>
              <div className="EngineeringConsulting">
                <div className="cardImage" >
                  <img
                    src={item.Sample }
                    style={{height:'30vh'}}
                    
                  />
                </div>

                <div className="cardText mt-2 p-2">
                  <h3 className='text-e2'>{item.CoreServicesName}</h3>
                  <p>{item.CoreServicesDetails1}</p>
                </div>
                <div className="border1 ms-2 me-2 mb-3 rounded rounded-5"></div>
                <div
                  style={{ color: '#f8f9fa' }}
                  dangerouslySetInnerHTML={{
                    __html: item.DetailingServicesName || '',
                  }}
                  className='text-ep1'
                />
              </div>

              {/* Button to Open Project Samples */}
              <div>
                <button
                  className="btn btnServicesS1 w-100 mt-4"
                  style={{
                    backgroundColor: '#ed1b24e8',
                    color: '#fff',
                    boxShadow: '0 2px 20px rgba(0, 0, 0, 0.2)',
                  }}
                  onClick={handleOpen}
                >
                  Project Samples
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
