import React from "react";
import "../css/Experience.css";
import { Link } from "react-router-dom";
import { scrollToTop } from '../UtilityFunction/scrollToTop.js';
import "../css/home.css";
import IntersectionObserverComponent from '../UtilityFunction/IntersectionObserverComponent.js'; 

export default function EXPERIENCE() {
  return (
    <div className="row p-4 m-5 ms-auto me-auto ec1 fontpadding1" style={{ width: "90%" }}>
      <IntersectionObserverComponent /> 
      
      <div className="col-lg-6 p-3">
        <h1 style={{ color: "#dc3545" }} className="text1">
          YEARS OF EXPERIENCE
        </h1>
        <div
          className="rounded rounded-4 mt-3"
          style={{
            width: "20%",
            height: "4px",
            backgroundColor: "rgb(220, 53, 69)",
          }}
        ></div>
        <h2 className="mt-3 text3">SERVICING PROJECTS ACROSS THE WORLDWIDE</h2>
      </div>
      {/* div2 */}
      <div className="col-lg-6 col-md-12 col-sm-12 p-3">
        <p className="text2">
          EDCAT (Engineering Design Consultancy and Technology) stands as a
          leading provider of specialized VDM/BIM/CAD service and Consulting,
          offering innovative solutions in the Oil and Gas sector, as well as
          industrial and residential projects. Committed to precision and
          excellence, EDCAT is a trusted partner for addressing complex
          engineering design challenges.
        </p>

        <Link to="../about">
          <button
            type="button"
            className="btn btn-danger"
            onClick={scrollToTop}
          >
            About us
          </button>
        </Link>
      </div>
    </div>
  );
}
